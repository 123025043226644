#parent {
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    min-height: 100vh;
    gap: 0.75rem;
    overflow: hidden;
    position: relative;
}

#holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: relative;
    gap: 0.75rem;
    padding: 1.5rem;
}

.background {
    z-index: -2;
    width: 61rem;
    height: 57.625rem;
    border-radius: 100%;
    background: #CECECE;
    filter: blur(200px);
    position: absolute;
}

.background-logo {
    color: rgba(255, 255, 255, 0.70);
    font-size: 25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-transform: lowercase;
    position: absolute;
    top: calc(50vh - 28.8125rem);
    z-index: -1;
}

.action-button {
    order: 0;
    display: flex;
    padding: 0.1rem 1.2rem 0.1rem 1.2rem;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-radius: 17px;
    /* background: #333333; */
    /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25); */
    /* backdrop-filter: blur(5px); */
    font-weight: 550;
    color: #333333;
    width: 8rem;
    transition: transform .6s cubic-bezier(0.25, 1, 0.5, 1);
    border: 1.2px solid rgba(216, 216, 216, 0.90);
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(100px);
}


#action-button-icon {
    transition: transform .6s cubic-bezier(0.25, 1, 0.5, 1);
}

.action-button:hover {
    transform: scale(1.025);
    cursor: pointer;
}

/* .container {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 33px;
    padding-top: 80px;
} */

.container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 2rem;
    /* margin-top: 2rem;
    margin-bottom: 2rem; */
}

/* #container-one {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    gap: 33px;
} */

#container-one {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    gap: 2rem;
}

/* #container-two {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    gap: 33px;
} */

#container-two {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    gap: 2rem;
}

#base {
    width: 85vw;
    max-width: 802px;
    height: 100%;
    border-radius: 1.56rem;
    order: 1;
    padding: 3.5rem 1rem 3.5rem 1rem;
    border: 1.3px solid rgba(216, 216, 216, 0.90);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: -10px 10px 100px 0px rgba(206, 206, 206, 0.25);
    backdrop-filter: blur(5px);
}

@media only screen and (max-width: 800px) {
    #parent {
        flex-direction: column;
        justify-content: center;
    }

    .container {
        flex-direction: column;
    }

    #container-one {
        justify-content: center;

    }

    #container-two {
        justify-content: center;
    }

}

.plannedTodoDivider {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    justify-content: space-between;
    color: white;
    border-radius: 25px;
    align-items: center;
    margin: 0;
    margin-bottom: 1rem;
    gap: 1.5rem;
    position: relative;
}

.plannedTodoDivider p {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: lowercase;
}

#plannedTasks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.plannedPriorityNumber {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    /* Makes the div circular */
    z-index: 1;
    min-width: 2.625rem;
    /* Size of the circle */
    min-height: 2.625rem;
    /* Size of the circle */
    width: 2.625rem;
    /* Size of the circle */
    height: 2.625rem;
    /* Size of the circle */
    font-size: 1.25rem;
    font-weight: 600;
    /* Adjust the font size as necessary */
    color: #000;
    /* Change the text color as needed */
}

#noPlannedTasks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
    gap: 0.5rem;
}

#noPlannedTasksText {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
}

@media only screen and (min-width: 1024px) {

    #container-two {
        position: relative;
        left: 10%;
        padding-top: 8rem;
        gap: 0;
    }

    #container-one {
        position: relative;
        left: 15%;
        gap: 0;
    }

}

@media only screen and (min-width: 820px) and (max-width: 1024px) {

    #container-two {
        position: relative;
        left: 5%;
        padding-top: 8rem;
        gap: 0;
    }

    #container-one {
        position: relative;
        left: 10%;
        gap: 0;
    }

}

@media only screen and (max-width: 430px) {
    #noPlannedTasks {
        flex-direction: column;
    }
}