#page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.page {
    order: 1;
    /* width: 1200px;
    height: 748px; */
    /* width: 75rem; */
    width: 90vw;
    max-width: 80rem;
    /* height: 46.75rem; */
    height: 80vh;
    border-radius: 1.56rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.85rem;
    /* background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px); */
    background: rgba(255, 255, 255, 0.05);
    box-shadow: -10px 10px 100px 0px rgba(206, 206, 206, 0.25);
    backdrop-filter: blur(15px);
    overflow: hidden;
}

.page-background {
    z-index: -2;
    width: 976px;
    height: 922px;
    border-radius: 100%;
    background: #CECECE;
    filter: blur(200px);
    position: absolute;
}

.page-background-logo {
    color: rgba(255, 255, 255, 0.70);
    font-size: 400px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-transform: lowercase;
    position: absolute;
    top: 0;
    z-index: -1;
}

.page-color-block {
    width: 100%;
    padding-top: 1.3rem;
    padding-bottom: 0.4rem;
    min-height: 13%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
}

#streamline-logo {
    text-decoration: none;
    align-self: flex-start;
    margin-left: 0.5rem;
}

#streamline-button {
    background-color: transparent;
    border: none;
    color: #C0C0C0;
    font-size: 1.35rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: lowercase;
    transition: all .6s cubic-bezier(0.25, 1, 0.5, 1);
}

#streamline-button:hover {
    cursor: pointer;
}

.priority-text {
    color: #FFF;
    /* font-size: 30px; */
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: lowercase;
    margin: 0px;
    padding: 0px;
    margin-left: 1.875rem;
    order: 1;
}

#priority-buttons {
    order: 2;
    margin-left: 0;
    margin-right: 1.875rem;
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.priority-button {
    text-decoration: none;
    padding: 0;
    margin: 0;
    /* width: 44px;
    height: 44px; */
    width: 2.75rem;
    height: 2.75rem;
    fill: white;
    border-radius: 100%;
    border: 0;
    transition: all .6s cubic-bezier(0.25, 1, 0.5, 1);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
}

.priority-button:hover {
    cursor: pointer;
    transform: scale(1.02);
    opacity: 70%;
}

#grab-handle {
    width: 6.25rem;
    height: 0.5625rem;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.30);
    position: absolute;
    left: 50%;
    top: 18%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 540px) {

    html {
        font-size: 14px;
    }

    .priority-text {
        font-size: 1.5rem;
        margin: 0;
    }

    .priority-button {
        height: 2rem;
        width: 2rem;
    }

    .page-color-block {
        padding-top: 2rem;
        padding-bottom: 1rem;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
    }

    #priority-buttons {
        order: 2;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    #grab-handle {
        top: 13%;
    }
}

@media only screen and (max-width: 700px) and (min-width: 635px) {

    .page-color-block {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }

    #priority-buttons {
        order: 2;
        margin-left: 0;
        margin-right: 1.875rem;
        gap: 1.5rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

}

@media only screen and (max-width: 635px) and (min-width: 540px) {
    html {
        font-size: 16px;
    }

    .priority-text {
        margin: 0;
    }

    .page-color-block {
        padding-top: 2rem;
        padding-bottom: 1rem;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
    }

    #grab-handle {
        top: 13%;
    }
}