@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

html, body {
  margin: 0;
  font-family: 'SF Pro Display', sans-serif;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: 'SF Pro Display', sans-serif
}

