.todos-container {
    margin: 0;
    padding: 0;
    padding: 1.56rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 95%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none; 
}

.todos-container::-webkit-scrollbar {
    display: none; 
}

.todo {
    margin: 0;
    padding: 0;
    padding-left: 0.375rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;
    height: 100%;
    width: 100%;
    transition: all .2s cubic-bezier(0.25, 1, 0.5, 1);
}

.todo:hover {
    background: rgba(181, 181, 181, 0.1);
    border-radius: 0.625rem;
}

.todo-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.56rem;
    height: 1.56rem;
    border-radius: 0.1875rem;
    border: 0.0625rem solid;
    background: rgba(255, 255, 255, 0.60);
    transition: all .2s cubic-bezier(0.25, 1, 0.5, 1);
}

.todo-text {
    color: #201B20;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all .2s cubic-bezier(0.25, 1, 0.5, 1);
    width: 100%;
}

.checked-todo-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.56rem;
    height: 1.56rem;
    border-radius: 3px;
    border: 0.0625rem solid #201B20;
    background: #201B20;
    transition: all .2s cubic-bezier(0.25, 1, 0.5, 1);
}

.todo-header-text {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: all .2s cubic-bezier(0.25, 1, 0.5, 1);
    text-decoration: underline;
}

#todo-text-editing {
    outline: none;
    border-style: none;
    box-sizing: content-box;
    background-color: transparent;
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
    width: 100%;
    opacity: 0.7;
}

#countdown-text {
    padding: 0;
    margin: 0;
    color: white;
    font-weight: 500;
    font-size: 0.875rem;
}