/* .card {
    width: 310px;
    border-radius: 25px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    transition: all .6s cubic-bezier(0.25, 1, 0.5, 1);
    padding: 0px;
    overflow: hidden;
    border: 0;
} */

.card {
    width: clamp(290px, 40vw, 310px);
    border-radius: 1.56rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    transition: all .6s cubic-bezier(0.25, 1, 0.5, 1);
    padding: 0;
    overflow: hidden;
    border: 0;
    background: rgba(255, 255, 255, 0.95); 
    backdrop-filter: blur(15px);
}

.card:hover {
    cursor: pointer;
}

.card p {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    margin-left: 1.56rem;
    margin-top: 1.56rem;
    z-index: 1;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: lowercase;
    width: 60%;
}

.color-block {
    width: 100%;
    position: absolute;
    transition: all .6s cubic-bezier(0.25, 1, 0.5, 1);
}

#card-one .color-block {
    height: 33%;
    top: 0;
    left: 0;
}

#card-one:hover .color-block {
    height: calc(33% + 3%);
}

#card-two .color-block {
    height: 65%;
    bottom: 0;
    right: 0;
}

#card-two:hover .color-block {
    height: calc(65% + 3%);
}

#card-three .color-block {
    height: 40%;
    bottom: 0;
    right: 0;
}

#card-three:hover .color-block {
    height: calc(40% + 5%);
}

#card-four .color-block {
    height: 45%;
    bottom: 0;
    right: 0;
}

#card-four:hover .color-block {
    height: calc(45% + 5%);
}

#card-one {
    order: 1;
    /* height: 385px; */
    height: 24rem;
    filter: drop-shadow(-10px 10px 100px rgba(121, 22, 22, 0.25));
}

#card-three {
    order: 2;
    /* height: 175px; */
    height: 11rem;
    filter: drop-shadow(-10px 10px 100px rgba(75, 75, 75, 0.28));

}

#card-two {
    order: 1;
    /* height: 324px; */
    height: 20.25rem;
    filter: drop-shadow(-10px 10px 100px rgba(22, 117, 100, 0.28));
}

#card-four {
    order: 2;
    /* height: 200px; */
    height: 12.5rem;
    filter: drop-shadow(-10px 10px 100px rgba(17, 87, 128, 0.28));
}

.priorityNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%; /* Makes the div circular */
    position: absolute;
    top: 0.8rem; /* Adjust as necessary for your layout */
    right: 0.8rem; /* Adjust as necessary for your layout */
    z-index: 1;
    min-width: 2.625rem; /* Size of the circle */
    min-height: 2.625rem; /* Size of the circle */
    width: 2.625rem; /* Size of the circle */
    height: 2.625rem; /* Size of the circle */
    font-size: 1.25rem;
    font-weight: 600; /* Adjust the font size as necessary */
    color: #000; /* Change the text color as needed */
}

@media only screen and (min-width: 820px) {
    .container {
        /* Remove any flexbox settings that conflict with absolute positioning */
        justify-content: flex-start;
        align-items: flex-start;
    }

    .card {
        position: relative;
    }

    /* Specific positioning for each card */
    #card-one {
        transform: rotate(-2.988deg);
        z-index: 7;
    }

    #card-two {
        transform: rotate(4.769deg);
        z-index: 8;
    }

    #card-four {
        left: -20%;
        transform: rotate(-5.181deg);
        z-index: 9;
    }

    #card-three {
        left: -25%;
        transform: rotate(-8.951deg);
        z-index: 10;
    }
}